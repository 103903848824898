import { StateService } from '@glideroggan/common';
import { FASTElement, Observable, css, customElement, html, observable, when } from '@microsoft/fast-element';

const template = html<TaskCounter>/*html*/`
${when(x => x.ready, html/*html*/`
<div class="container">
    <div class="divider">
        <p>${x => x.translations["WIDGET_CARD_TASK_COUNTER_PAST_WEEK"]}</p>
        <p past-week>${x => x.previousTaskNumber}</p>
    </div>
    <div>
        <span arrow>
            <svg up class="${x => x.whichArrow('up')}" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                <path
                    d="M384 160c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32V288c0 17.7-14.3 32-32 32s-32-14.3-32-32V205.3L342.6 374.6c-12.5 12.5-32.8 12.5-45.3 0L192 269.3 54.6 406.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160c12.5-12.5 32.8-12.5 45.3 0L320 306.7 466.7 160H384z" />
            </svg>
            <svg down class="${x => x.whichArrow('down')}" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
                <path
                    d="M384 352c-17.7 0-32 14.3-32 32s14.3 32 32 32H544c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32s-32 14.3-32 32v82.7L342.6 137.4c-12.5-12.5-32.8-12.5-45.3 0L192 242.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0L320 205.3 466.7 352H384z" />
            </svg>
        </span>
        <span delta>${x => x.delta}</span>
    </div>
    <div class="divider">
        <p>${x => x.translations["WIDGET_CARD_TASK_COUNTER_CURRENT"]}</p>
        <p current>${x => x.currentTaskNumber}</p>
    </div>
</div>
`)}`

const styles = css`
.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    /* min-height: 100px; */
    height: 100%;
    margin-top: .5rem;
    gap: 2rem;
}
p {
    font-size: 1.5rem;
    font-weight: 500;
    /* color: #fff; */
    margin: 0;
}
p:first-child {
    font-size: 1.2rem;
    font-weight: 400;
}
.divider {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* width: 45%; */

    height: 100%;
    /* background-color: #b6b6b6; */
    /* border-radius: 5px; */
    /* box-shadow: 0 0 5px rgba(0,0,0,.2); */
}
.hidden {
    display: none !important;
}
svg[up] {
    fill: #00ff00;
}
svg[down] {
    fill: #ff0000;
}
`


/**
 * returns the number of tasks completed last month
 * @param el element to spawn toast from, if needed
 * @returns {Promise<number>}
 */
const getLastMonth = async (el: HTMLElement): Promise<number> => {
    const today = new Date();
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    // console.log('last month', firstDayOfLastMonth, lastDayOfLastMonth)

    try {
        const stats = await StateService.getStats(el, firstDayOfLastMonth, lastDayOfLastMonth);
        return stats;
    } catch (error) {
        console.error('Error fetching stats for last month:', error);
        throw error; // rethrow the error to be handled by the caller
    }
};
/**
 * returns the number of tasks completed this month
 * @param el element to spawn toast from, if needed
 * @returns {Promise<number>}
 */
const getThisMonth = async (el: HTMLElement): Promise<number> => {
    const today = new Date();
    const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    // console.log('this month', firstDayOfThisMonth, today)

    try {
        const stats = await StateService.getStats(el, firstDayOfThisMonth, today);
        // console.log(stats);
        return stats;
    } catch (error) {
        console.error('Error fetching stats for this month:', error);
        throw error; // rethrow the error to be handled by the caller
    }
};

@customElement({
    name: 'task-counter',
    template,
    styles
})
export class TaskCounter extends FASTElement {
    previousTaskNumber: number;
    currentTaskNumber: number;
    delta: number;
    @observable ready: boolean = false;
    @observable translations: any = {};
    /**
     * returns either hidden or empty string
     * determines which arrow to show
     */
    whichArrow(upOrDown:string): string {
        if (this.delta >= 0) {
            return upOrDown === 'up' ? '' : 'hidden'
        } else if (this.delta < 0) {
            return upOrDown === 'down' ? '' : 'hidden'
        }
        return ''
    }
    constructor() {
        super();
        StateService.language.languageChange.on(this.languageChange.bind(this))
    }
    async connectedCallback(): Promise<void> {
        super.connectedCallback();

        const lang = await StateService.language.getLanguage();
        this.translations = await StateService.language.getTranslations(lang);

        this.previousTaskNumber = await getLastMonth(this);
        this.currentTaskNumber = await getThisMonth(this);
        this.delta = this.currentTaskNumber - this.previousTaskNumber;

        this.ready = true
    }
    private async languageChange(lang: string | undefined) {
        this.translations = await StateService.language.getTranslations(lang!);
        Observable.notify(this, 'translations')
    }
}

